import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import NewsPreview from './news-preview'
import Heading from '../heading'

import {colors, font, fontfamily} from '../../styles/variables'

const Wrapper = styled.section`
  display: block;
  background: #FAFFFA;
  /* background: #FDFEF4; */
  padding: 4rem 0;
  /* width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; */
  @media (min-width: 1024px) {
    padding: 4rem 2.4rem 8rem 2.4rem;
  }

`

const Inner = styled.div`
  max-width: 580px;
  margin: 0 auto;
`

const List = styled.ul`
  display: block;
  margin: 0 2.8rem;
`

const StyledLink = styled(props => <Link {...props} />)`
  font-size: 1.4rem;
  text-align: center;
  display: block;
  color: ${colors.navy};
`

function NewsPreviewList (props) {
  return (
    <Wrapper>
      <Inner>
        <Heading title='ニュース' titleEn='NEWS' />
        <List>
          {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id} >
              <NewsPreview {...node} isInList />
            </li>
          ))}
        </List>
        <StyledLink to='/news/archive/'>過去のニュースを見る</StyledLink>
      </Inner>
    </Wrapper>
  )
}

NewsPreviewList.defaultProps = {
  nodes: []
}

export default NewsPreviewList
