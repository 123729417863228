import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import {buildImageObj, getNewsUrl, getPageUrl, getAdmissionsUrl, getSummerWinterSchoolsUrl} from '../../lib/helpers'
import {imageUrlFor} from '../../lib/image-url'
import PortableText from '../portableText'
import {colors, font, fontfamily} from '../../styles/variables'

const Wrapper = styled.div`
  display: block;
  margin: 0 0 4rem 0;
  position: relative;
`

const ImageBg = styled.div`
  /* background-image: url(../../greenBg.svg); */
  /* background-size: 100%; */
  /* background-position: center -5px; */
  /* background-repeat: no-repeat; */
  /* padding: 0 0 20px 0; */
`

const ImageMask = styled.div`
  display: block;
  /* filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1) ); */
`

const Image = styled.img`
  border-radius: 5px;
  box-shadow: 0 4px 18px 0 rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  /* mask-image: url(../../mask_1.svg);
  mask-repeat: none;
  mask-position: bottom;
  mask-size: 100%; */
  &:hover{
    box-shadow: none;
    opacity: 0.8;

  }
`

const ImageLink = styled(props => <Link {...props} />)`
`

const StyledLink = styled(props => <Link {...props} />)`
  // font-weight: 700;
  color: ${colors.blue};
  text-decoration: none;
  font-family: ${fontfamily.jaRounded};
  font-size: 1.6rem;
  position: absolute;
  bottom: 15%;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  width: 90;;
  padding: 0.6rem 1.2rem;
  border-radius: 5px 0 0 5px;
  @media (min-width: 1024px) {
    font-size: 1.8rem;
  }

`

class BannerPreview extends React.Component {
  constructor (props) {
    super(props)
    this.url = ''
  }

  componentWillMount () {
    if (this.props.link._type === 'news') {
      this.url = getNewsUrl(this.props.publishedAt, this.props.link.slug.current)
    }
    if (this.props.link._type === 'pages') {
      this.url = getPageUrl(this.props.link.slug.current)
    }
    if (this.props.link._type === 'admissions') {
      this.url = getAdmissionsUrl(this.props.publishedAt, this.props.link.slug.current)
    }
    if (this.props.link._type === 'summerWinterSchools') {
      this.url = getSummerWinterSchoolsUrl(this.props.publishedAt, this.props.link.slug.current)
    }
  }
  render () {
    return (
      <Wrapper>
        <ImageBg>
          <ImageMask>
            <ImageLink to={this.url}>
              <Image
                src={imageUrlFor(buildImageObj(this.props.mainImage))
                  .width(1200)
                  .height(680)
                  .fit('crop')
                  .auto('format')
                  .url()}
                alt={this.props.mainImage.alt}
              />
            </ImageLink>
          </ImageMask>
        </ImageBg>
        <StyledLink to={this.url}>
          {this.props.title}
        </StyledLink>
      </Wrapper>
    )
  }
}

export default BannerPreview
