import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  buildImageObj
} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import GraphQLErrorList from '../components/graphql-error-list'

import Container from '../components/container'
import PortableText from '../components/portableText'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import Hero from '../components/home/hero'
import BannersPreviewList from '../components/home/banners-preview-list'
import NewsPreviewList from '../components/home/news-preview-list'
import AboutPreview from '../components/home/about-peview'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      heroImage{
        ...SanityImage
        alt
      }
      title
      subTitle
      _rawIntro
      _rawFooter
      description
      keywords
    }

    about: sanityPages(
       slug: { current: {eq: "about"} }
      ) {
        title
        titleEn
        _rawExcerpt
        mainImage{
          ...SanityImage
          alt
        }
      }

    news: allSanityNews(
      limit: 5
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            _type
            id
            publishedAt
            title
            slug{
              current
            }
            categories{
              _id
              title
              catParent
              slug{
                current
              }
            }
          }
        }
      }

      banners: allSanityBanners(
      limit: 4
      sort: { fields: [publishedAt], order: DESC }
      filter: { publishedAt: { ne: null } }
      ) {
      edges {
        node {
          _type
          id
          publishedAt
          title
          mainImage{
            ...SanityImage
            alt
          }
          link{
            ... on SanityAdmissions{
              _type
              slug{
                current
              }
            }
            ... on SanitySummerWinterSchools{
              _type
              slug{
                current
              }
            }
            ... on SanityNews{
              _type
              slug{
                current
              }
            }
            ... on SanityPages{
              _type
              slug{
                current
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const about = (data || {}).about

  const bannerNodes = (data || {}).banners
    ? mapEdgesToNodes(data.banners)
      .filter(filterOutDocsPublishedInTheFuture)
    : []
  const newsNodes = (data || {}).news
    ? mapEdgesToNodes(data.news)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        {site.heroImage && (
          <Hero
            heroImage={site.heroImage}
            _rawIntro={site._rawIntro}
          />
        )}
        {bannerNodes && (
          <BannersPreviewList
            nodes={bannerNodes}
          />
        )}
        {newsNodes && (
          <NewsPreviewList
            nodes={newsNodes}
          />
        )}
        {about && (
          <AboutPreview
            title={about.title}
            titleEn={about.titleEn}
            mainImage={about.mainImage}
            _rawExcerpt={about._rawExcerpt}
          />
        )}
      </Container>
    </Layout>
  )
}

export default IndexPage
