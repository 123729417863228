import React from 'react'
import styled from 'styled-components'

import {buildImageObj, cn, toPlainText} from '../../lib/helpers'
import {imageUrlFor} from '../../lib/image-url'
import PortableText from '../portableText'
import {colors, font, fontfamily} from '../../styles/variables'

import Heading from '../heading'
import Button from '../button'

const Wrapper = styled.section`
  display: block;
  padding: 4rem 0;
  @media (min-width: 1024px) {
    padding: 8rem 2.4rem;
  }

`

const ImageWrapper = styled.div`
  display: block;
  margin: 0 2.4rem 4rem 2.4rem;
`

const Image = styled.img`
  display: block;
  border-radius: 5px;
`

const Text = styled.div`
  margin: 0 2.4rem 4rem 2.4rem;
  @media (min-width: 768px) {
    margin: 0 auto;
    max-width: 680px;
    margin-bottom: 8rem
  }

`

const AboutPreview = props => {
  const {title, titleEn, _rawExcerpt, mainImage} = props
  return (
    <Wrapper>
      <Heading title='ニューヨーク育英学園について' titleEn='ABOUT' />
      <ImageWrapper>
        <Image
          src={imageUrlFor(buildImageObj(mainImage))
            .width(2400)
            .fit('crop')
            .auto('format')
            .url()}
          alt={mainImage.alt}
        />
      </ImageWrapper>

      {_rawExcerpt && (
        <Text>
          <PortableText blocks={_rawExcerpt} />
        </Text>
      )}
      <Button link='/about/' label='学園について' />
      <Button link='/admissions/' label='入園案内' />
    </Wrapper>
  )
}
export default AboutPreview
