import React from 'react'
import styled from 'styled-components'
import BannerPreview from './banner-preview'

const Wrapper = styled.section`
  display: block;
  @media (min-width: 1024px) {
    padding: 8rem 2.4rem;
  }
`

const List = styled.ul`
  display: block;
  margin: 0 2.4rem;
  width: 90%;
  margin: 0 auto;
  max-width: 480px;
  @media (min-width: 768px) {
    max-width: 480px;
  }
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    max-width: 1024px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

const Item = styled.li`
  flex: 45% 0 0;
  display: block;
  @media (min-width: 768px) {
    margin-bottom: 8rem;
  }
`

function NewsPreviewGrid (props) {
  return (
    <Wrapper>
      <List>
        {props.nodes &&
          props.nodes.map(node => (
            <Item key={node.id} >
              <BannerPreview {...node} isInList />
            </Item>
          ))}
      </List>
    </Wrapper>
  )
}

NewsPreviewGrid.defaultProps = {
  nodes: []
}

export default NewsPreviewGrid
