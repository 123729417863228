import React from 'react'
import styled from 'styled-components'

import {buildImageObj, cn} from '../../lib/helpers'
import {imageUrlFor} from '../../lib/image-url'
import PortableText from '../portableText'
import {colors, font, fontfamily} from '../../styles/variables'

const Wrapper = styled.div`
  display: block;
  position: relative;
  margin: 30px 0 4rem 0;
`

const ImageBg = styled.div`
  background-image: url('/greenBg.svg');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 0 0 20px 0;
`

const ImageMask = styled.div`
  display: block;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.4) );
`

const Image = styled.img`
  mask-image: url('/mask_1.svg');
  mask-repeat: none;
  mask-position: bottom;
  mask-size: 100%;
`

const Text = styled.div`
  font-weight: 700;
  color: ${colors.lightBlack};
  font-family: ${fontfamily.jaRounded};
  font-size: 1.6rem;
  position: absolute;
  bottom: 15%;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.6rem 1.2rem;
  border-radius: 5px 0 0 5px;
  @media (min-width: 1024px) {
    p{
      font-size: 2.0rem;
      border-radius: 5px;
    }
  }

`

const Hero = props => {
  const {heroImage, _rawIntro} = props
  return (
    <Wrapper>
      <ImageBg>
        <ImageMask>
          <Image
            src={imageUrlFor(buildImageObj(heroImage))
              .width(2400)
              .fit('crop')
              .auto('format')
              .url()}
            alt={heroImage.alt}
          />
        </ImageMask>
      </ImageBg>
      <Text>
        <PortableText blocks={_rawIntro} />
      </Text>
    </Wrapper>
  )
}
export default Hero
